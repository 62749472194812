import { ReactNode } from 'react';

interface FooterProps {
  sticky?: boolean;
  children: ReactNode;
}

export default function Footer({ sticky = true, children }: FooterProps) {
  return (
    <div
      className={`flex flex-col w-full bg-rf-gray-f9 p-4 space-y-2
	  sm:max-w-lg xl:max-w-xl mx-auto
      ${sticky ? 'sticky bottom-0' : ''} flex justify-center`}
    >
      {children}
    </div>
  );
}
